<template>
  <div>
    <p class="ql-editor" v-html="page_val"></p>
  </div>
</template>

<script>
import { searchByType } from "@/api/index";
export default {
  name: "protocol",
  data() {
    return {
      page_val: ""
    };
  },
  created() {
    this.getProtocol();
  },
  methods: {
    getProtocol() {
      searchByType({ type_bn: "s_page_type_901" })
        .then(res => {
          this.page_val = res.data[0].page_val;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style></style>
